//#region
import App from './App/App';
import ReactDOM from 'react-dom/client';
import * as worker from './Utilities/Worker';

import './Assets/Styles/index.css';
//#endregion

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);

worker.unregister();

//#region
import { IconCircleX } from '@tabler/icons-react';
import { ActionIcon, Container, Grid, Paper } from '@mantine/core';
//#endregion

// eslint-disable-next-line
export default (props: any) => {
  const onRemoveTrade = (id: string) => {
    const idx = props.trades?.findIndex((trade: any) => trade.id === id);
    props.setTrades.remove(idx);
  };

  const PageTrade = (props: any) => (
    <Paper mb={10}>
      <Grid className="font-bold px-2">
        <Grid.Col span={3}>{Math.round(props.trade.aInvest)}</Grid.Col>
        <Grid.Col span={3}>{Math.round(props.trade.aTrade)}</Grid.Col>
        <Grid.Col
          span={2}
          className={
            props.trade.status === 'WIN' ? 'text-green-500' : 'text-red-500'
          }
        >
          {props.trade.status}
        </Grid.Col>
        <Grid.Col span={4}>
          <div className="flex justify-content-between flex-wrap">
            {Math.round(props.trade.aTotal)}
            <ActionIcon
              mt={-2}
              variant="transparent"
              onClick={() => onRemoveTrade(props.trade.id)}
            >
              <IconCircleX />
            </ActionIcon>
          </div>
        </Grid.Col>
      </Grid>
    </Paper>
  );

  return (
    <Container size="xs" mt={20}>
      {props.trades.length > 0 &&
        [...props.trades].reverse()?.map((trade: ITrade) => {
          return <PageTrade trade={trade} key={trade.id} />;
        })}
    </Container>
  );
};

//#region
import { useState } from 'react';
import axios, { Method } from 'axios';
//#endregion

export const useAxios = (path?: string) => {
  const [token, setToken] = useState(undefined);
  const [loading, setLoading] = useState(false);

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  axios.interceptors.request.use((config) => {
    setLoading(true);
    if (token) {
      config.headers.authorization = `Basic ${token}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    (res) => {
      setLoading(false);
      const response = res?.headers?.authorization;
      if (response && response.includes('Basic')) {
        setToken(response.replace('Basic ', ''));
      }
      return res;
    },
    (err) => {
      setLoading(false);
      if (err && err.response && err.response.status === 503) {
        return (window.location.href = '/down');
      }
      return err;
    }
  );

  //#region

  const req = async (method: Method, url?: string, data?: any) => {
    const response = await axios({ method, url: endUrl(url), data });
    return response?.data;
  };

  const get = async (opt?: IUrl) => await req('GET', opt?.url);

  const del = async (opt?: IUrl) => await req('DELETE', opt?.url);

  const put = async (opt: IData) => await req('PUT', opt?.url, opt.data);

  const post = async (opt: IData) => await req('POST', opt?.url, opt.data);

  const patch = async (opt: IData) => await req('PATCH', opt?.url, opt.data);

  const endUrl = (url?: any) => (path && url ? path + url : url ? url : path);

  //#endregion

  return { axios, loading, get, del, post, put, patch };
};

//#region
import { Router } from '../../App/Route';
import { Button, Flex, Modal, Text } from '@mantine/core';
//#endregion

//eslint-disable-next-line
export default (props: any) => {
  return (
    <Modal
      centered
      size="xs"
      opened={props.openLogOff}
      onClose={() => props.toggleLogOff()}
      title={<b>Confirm</b>}
    >
      <Text>Are you sure to logoff the app now?</Text>
      <Flex direction="row-reverse" mt="xl" gap="md">
        <Button color="red" onClick={() => Router.navigate('login')}>
          YES, LOG-OFF
        </Button>
        <Button
          color="gray"
          variant="outline"
          onClick={() => props.toggleLogOff()}
        >
          CANCEL
        </Button>
      </Flex>
    </Modal>
  );
};

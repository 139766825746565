//#region
import { Image } from '@mantine/core';
import Login from '../Assets/Images/login.png';
import FormLogin from '../Sections/Forms/FormLogin';
//#endregion

// eslint-disable-next-line
export default () => {
  return (
    <div className="inline-flex w-12">
      <div className="hidden md:block w-8">
        <div className="h-12 flex align-items-center justify-content-center border-gray-700 border-end">
          <Image w="80vh" radius="md" src={Login} />
        </div>
      </div>
      <div className="hidden md:block w-4">
        <FormLogin />
      </div>
      <div className="block md:hidden w-12">
        <FormLogin />
      </div>
    </div>
  );
};

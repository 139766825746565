//#region
import {
  Box,
  Title,
  Paper,
  TextInput,
  PasswordInput,
  Group,
  Checkbox,
  Button,
  Avatar,
  Center,
} from '@mantine/core';
import { Router } from '../../App/Route';
import { useForm } from '@mantine/form';
import { Notify } from '../../App/Notify';
import { usePage } from '../../Hooks/usePage';
import { useAxios } from '../../Hooks/useAxios';
import { useStore } from '../../Hooks/useStore';
import { useShallowEffect } from '@mantine/hooks';
import { decode64, encode64 } from '../../Utilities/Core';
//#endregion

// eslint-disable-next-line
export default () => {
  const { setLoggedOn } = usePage('APP - LOGIN');
  const [token, setToken] = useStore({ key: 'token' });
  const [remember, setRemember] = useStore({
    key: 'remember',
    defaultValue: true,
  });

  useShallowEffect(() => {
    setLoggedOn(false);
  }, []);

  //#region
  let initialValues = { email: '', password: '' };
  if (remember && token) {
    const creds = decode64(token).split(':');
    initialValues = { email: creds[0], password: creds[1] };
  }
  const form = useForm({
    initialValues,
    validate: {
      email: (value) => (value ? null : 'Please enter email!'),
      password: (value) => (value ? null : 'Please enter password!'),
    },
    validateInputOnBlur: ['email', 'password'],
  });

  const { loading, post } = useAxios('/api/login/');
  const onSubmitLogin = async (data: any) => {
    setToken(encode64(`${data.email}:${data.password}`));
    const response = await post({ data });
    if (response && response.token) {
      setLoggedOn(true);
      Notify('Success', 'Email & Password validated successfully!', () => {
        Router.navigate('root');
      });
    } else {
      Notify('Failure', 'Email / Password has been invalid!');
    }
  };
  //#endregion

  return (
    <div className="h-12 flex align-items-center justify-content-center">
      <Box mt="-10rem">
        <Center>
          <Avatar src="logo512.png" size="150px" />
        </Center>
        <Title ta="center">Welcome to login!</Title>
        <Paper
          style={{ width: '360px' }}
          className="p-4 mt-4 shadow-4 border-round-md"
        >
          <form onSubmit={form.onSubmit(onSubmitLogin)}>
            <TextInput
              type="email"
              placeholder="Your email"
              {...form.getInputProps('email')}
            />
            <PasswordInput
              mt="md"
              placeholder="Your password"
              {...form.getInputProps('password')}
            />
            <Group justify="space-between" mt="lg">
              <Checkbox
                checked={remember}
                label="Remember me"
                onChange={(event) => setRemember(event.currentTarget.checked)}
              />
            </Group>
            <Button size="md" fullWidth mt="lg" type="submit" loading={loading}>
              LOG-ON
            </Button>
          </form>
        </Paper>
      </Box>
    </div>
  );
};

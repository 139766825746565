//#region
import { useSetState } from '@mantine/hooks';
import { createContext, useContext } from 'react';
//#endregion

const StateContext = createContext<TState | undefined>(undefined);

export const StateProvider = (props: any) => {
  const [state, setState] = useSetState<IState>({
    maxTrade: 10,
    maxLoss: 3,
    maxWin: 10,
    maxWait: '10',
  });
  return (
    <StateContext.Provider value={{ state, setState }}>
      {props.children}
    </StateContext.Provider>
  );
};

export const useStateProvider = () => {
  const { state, setState } = useContext(StateContext) as TState;
  return { state, setState };
};

//#region
import { Image } from '@mantine/core';
import Down from '../Assets/Images/down.png';
import { useDocumentTitle } from '@mantine/hooks';
//#endregion

// eslint-disable-next-line
export default () => {
  useDocumentTitle('APP - DOWN');
  return (
    <div className="flex align-items-center justify-content-center h-screen">
      <div className="empty">
        <div className="">
          <Image w="40vh" radius="md" src={Down} />
        </div>
        <p className="empty-title text-2xl font-bold">Under Maintenance</p>
        <p className="empty-subtitle text-secondary">
          Sorry for the inconvenience caused!
        </p>
        <div className="empty-action"></div>
      </div>
    </div>
  );
};

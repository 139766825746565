//#region
import { StateProvider } from './State';
import { ThemeProvider } from './Theme';
import { NotifyProvider } from './Notify';
import { RouteProvider, useRouter } from './Route';
//#endregion

// eslint-disable-next-line
export default () => {
  const route = useRouter();
  return (
    <StateProvider>
      <ThemeProvider>
        <NotifyProvider />
        <RouteProvider route={route} />
      </ThemeProvider>
    </StateProvider>
  );
};

//#region
import $ from 'jquery';
import { useStateProvider } from './State';
import { MantineProvider, createTheme } from '@mantine/core';
import { useColorScheme, useShallowEffect } from '@mantine/hooks';
//#endregion

const onChangeTheme = (color: 'dark' | 'light') => {
  $('html').attr('data-bs-theme', color);
  $('html').attr('data-mantine-color-scheme', color);
};

export const ThemeProvider = (props: any) => {
  const color = useColorScheme();
  const theme = createTheme({
    scale: 1, //1.6
  });
  onChangeTheme(color);
  return <MantineProvider theme={theme}>{props.children}</MantineProvider>;
};

export const useThemeProvider = () => {
  const color = useColorScheme();
  const { state, setState } = useStateProvider();

  const setTheme = () => {
    const color = state?.theme === 'dark' ? 'light' : 'dark';
    setState({ theme: color });
    onChangeTheme(color);
  };

  useShallowEffect(() => {
    setState({ theme: color });
  }, [color]);

  return { theme: state?.theme, setTheme };
};

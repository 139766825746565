//#region
import { Router } from '../App/Route';
import { useStore } from './useStore';
import { useDocumentTitle, useShallowEffect } from '@mantine/hooks';
//#endregion

export const usePage = (title: string) => {
  useDocumentTitle(title);
  const [loggedOn, setLoggedOn] = useStore({ key: 'loggedOn' });

  useShallowEffect(() => {
    !loggedOn && Router.navigate('login');
  }, [loggedOn]);

  return { loggedOn, setLoggedOn };
};

//#region
import { Router } from '../App/Route';
import { Image } from '@mantine/core';
import { usePage } from '../Hooks/usePage';
import Error from '../Assets/Images/error.png';
//#endregion

// eslint-disable-next-line
export default () => {
  usePage('APP - PAGE NOT FOUND');
  return (
    <div className="flex align-items-center justify-content-center h-screen">
      <div className="empty">
        <div className="">
          <Image w="40vh" radius="md" src={Error} />
        </div>
        <p className="empty-title text-2xl font-bold">Page not found</p>
        <p className="empty-subtitle text-secondary">
          Try adjusting your search or filter!
        </p>
        <div className="empty-action">
          <a
            href="#/"
            onClick={() => Router.goBack()}
            className="btn btn-primary"
          >
            Go to back
          </a>
        </div>
      </div>
    </div>
  );
};

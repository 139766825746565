import { ChangeEvent } from 'react';
import { useToggle } from '@mantine/hooks';
import ModalLogout from '../Modals/ModalLogout';
import { useStateProvider } from '../../App/State';
import { Button, Drawer, Grid, TextInput } from '@mantine/core';

//eslint-disable-next-line
export const usePanelLeft = () => {
  const [openPanelLeft, togglePanelLeft] = useToggle();
  return {
    togglePanelLeft,
    openPanelLeft,
  };
};

export const PanelLeft = (props: any) => {
  const [openLogOff, toggleLogOff] = useToggle();
  const { state, setState } = useStateProvider();
  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ [event.target.name]: Number(event.target.value) });
  };

  return (
    <Drawer
      position="left"
      className="relative"
      title={<b>Settings</b>}
      opened={props.openPanelLeft}
      onClose={props.togglePanelLeft}
    >
      <Grid>
        <Grid.Col span={6}>
          <p className="mt-2">MAXIMUM TRADE</p>
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            maxLength={2}
            name="maxTrade"
            placeholder="Trade"
            onChange={onChangeInput}
            defaultValue={state?.maxTrade?.toString()}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={6}>
          <p className="mt-2">MAXIMUM WIN</p>
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            maxLength={1}
            name="maxWin"
            placeholder="Win"
            onChange={onChangeInput}
            defaultValue={state?.maxWin?.toString()}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={6}>
          <p className="mt-2">MAXIMUM LOSS</p>
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            maxLength={1}
            name="maxLoss"
            placeholder="Loss"
            onChange={onChangeInput}
            defaultValue={state?.maxLoss?.toString()}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={6}>
          <p className="mt-2">MAXIMUM WAIT</p>
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            maxLength={2}
            name="maxWait"
            placeholder="Wait"
            onChange={onChangeInput}
            defaultValue={state?.maxWait?.toString()}
          />
        </Grid.Col>
      </Grid>
      <div className="absolute left-0 bottom-0 w-full px-2">
        <Button
          mb={8}
          fullWidth
          color="red"
          variant="light"
          onClick={() => toggleLogOff()}
        >
          LOG-OFF
        </Button>
      </div>
      <ModalLogout openLogOff={openLogOff} toggleLogOff={toggleLogOff} />
    </Drawer>
  );
};

//#region
import {
  IconCircleX,
  IconInfoCircle,
  IconCircleCheck,
} from '@tabler/icons-react';
import { Loader } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { Notifications, notifications } from '@mantine/notifications';
//#endregion

const NotifyType = {
  Info: { color: 'blue', icon: <IconInfoCircle /> },
  Failure: { color: 'red', icon: <IconCircleX /> },
  Success: { color: 'green', icon: <IconCircleCheck /> },
  Warning: { color: 'orange', icon: <Loader color="orange" /> },
};

export const Notify = (type: Type, message: string, callback?: any) => {
  notifications.show({
    message,
    title: type,
    onClose: callback,
    icon: NotifyType[type].icon,
    color: NotifyType[type].color,
  });
};

export const NotifyProvider = () => {
  const { width: w } = useViewportSize();
  return (
    <Notifications
      autoClose={3000}
      position={w < 768 ? 'top-center' : 'top-right'}
    />
  );
};

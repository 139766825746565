//#region
import {
  ActionIcon,
  Container,
  Group,
  Paper,
  TextInput,
  Title,
} from '@mantine/core';
import {
  IconCoinRupee,
  IconMoonStars,
  IconBrightnessDown,
  IconMenu2,
} from '@tabler/icons-react';
import { ChangeEvent } from 'react';
import { useThemeProvider } from '../../App/Theme';
import { useShallowEffect } from '@mantine/hooks';
import { PanelLeft, usePanelLeft } from '../Panels/PanelLeft';
//#endregion

// eslint-disable-next-line
export default (props: any) => {
  const { theme, setTheme } = useThemeProvider();
  const { openPanelLeft, togglePanelLeft } = usePanelLeft();

  useShallowEffect(() => {
    props.setConfig({
      pTrade: 10,
      pReturn: 85,
      pIterate: 73,
      aTotal: 1000,
    });
  }, []);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    props.setConfig({ [event.target.name]: Number(event.target.value) });
  };

  const onChangeTotal = () => {
    return props.trades && props.trades.length > 0
      ? props.trades[props.trades.length - 1].aTotal
      : props.config?.aTotal;
  };

  return (
    <Container size="xs" p={0}>
      <Paper py={1}>
        <div className="flex justify-content-between flex-wrap py-2 px-2">
          <ActionIcon
            ml={4}
            variant="transparent"
            onClick={() => togglePanelLeft()}
          >
            <IconMenu2 />
          </ActionIcon>
          <Group>
            <IconCoinRupee
              size={28}
              color={props.config?.aTotal <= onChangeTotal() ? 'green' : 'red'}
            />
            <Title
              order={2}
              ml={-14}
              onClick={() => {
                props.setConfig({
                  aTotal: onChangeTotal(),
                });
              }}
              c={props.config?.aTotal <= onChangeTotal() ? 'green' : 'red'}
            >
              {onChangeTotal()}
            </Title>
          </Group>
          <ActionIcon mr={8} variant="transparent" onClick={setTheme}>
            {theme === 'dark' ? (
              <IconBrightnessDown className="text-yellow-500" />
            ) : (
              <IconMoonStars className="text-blue-500" />
            )}
          </ActionIcon>
        </div>
        <form>
          <div className="flex flex-wrap justify-content-center gap-2 font-bold mb-3">
            <TextInput
              name="aTotal"
              className="w-11rem lg:w-7rem"
              placeholder="Trade Amount"
              onChange={onChangeInput}
              defaultValue={props.config?.aTotal}
            />
            <TextInput
              name="pReturn"
              className="w-11rem lg:w-7rem"
              placeholder="Trade Return"
              onChange={onChangeInput}
              defaultValue={props.config?.pReturn}
            />
            <TextInput
              name="pTrade"
              className="w-11rem lg:w-7rem"
              placeholder="Trade Percent"
              onChange={onChangeInput}
              defaultValue={props.config?.pTrade}
            />
            <TextInput
              name="pIterate"
              className="w-11rem lg:w-7rem"
              placeholder="Trail Percent"
              onChange={onChangeInput}
              defaultValue={props.config?.pIterate}
            />
          </div>
        </form>
      </Paper>
      <PanelLeft
        openPanelLeft={openPanelLeft}
        togglePanelLeft={togglePanelLeft}
      />
    </Container>
  );
};

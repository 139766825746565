//#region
import { AppShell } from '@mantine/core';
import { usePage } from '../Hooks/usePage';
import PageFooter from '../Sections/Pages/PageFooter';
import PageHeader from '../Sections/Pages/PageHeader';
import PageTrades from '../Sections/Pages/PageTrades';
import { useListState, useSetState, useViewportSize } from '@mantine/hooks';
//#endregion

//eslint-disable-next-line
export default () => {
  usePage('APP - HOME');
  const { width: w } = useViewportSize();
  const [config, setConfig] = useSetState<IConfig>({});
  const [trades, setTrades] = useListState<ITrade>([]);
  return (
    <AppShell withBorder={false} header={{ height: w < 768 ? 150 : 100 }}>
      <AppShell.Header className="bg-transparent">
        <PageHeader config={config} setConfig={setConfig} trades={trades} />
      </AppShell.Header>
      <AppShell.Main>
        <PageTrades trades={trades} setTrades={setTrades} />
      </AppShell.Main>
      <AppShell.Footer className="bg-transparent">
        <PageFooter config={config} trades={trades} setTrades={setTrades} />
      </AppShell.Footer>
    </AppShell>
  );
};

//#region
import { createRouter } from 'react-chicane';
import NotFound from '../Sections/NotFound';
import Down from '../Sections/Down';
import Login from '../Pages/Login';
import Home from '../Pages/Home';
//#endregion

const ROUTES: any = ['root', 'login', 'down'];

export const Router = createRouter({
  root: '/',
  login: '/login',
  down: '/down',
});

export const RouteProvider = (props: any) => {
  switch (props.route?.name) {
    case 'root':
      return <Home />;
    case 'login':
      return <Login />;
    case 'down':
      return <Down />;
    default:
      return <NotFound />;
  }
};

export const useRouter = () => Router.useRoute(ROUTES);

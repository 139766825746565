//#region
import { useWindowEvent } from '@mantine/hooks';
import { useState, useEffect, useCallback } from 'react';
//#endregion

const PREFIX = '5t0re';

const getLocalOrDefaultValue = (props: IStore) => {
  const stored = localStorage.getItem(`${PREFIX}.${props.key}`);
  return !stored || stored === 'undefined'
    ? props.defaultValue
    : JSON.parse(stored);
};

const setLocalOrDefaultValue = (key: string, value?: any) => {
  localStorage.setItem(`${PREFIX}.${key}`, JSON.stringify(value));
};

export const useStore = (props: IStore) => {
  const [value, setValue] = useState(getLocalOrDefaultValue(props));

  useEffect(() => {
    setLocalOrDefaultValue(props.key, value);
  }, [props.key, value]);

  const handleStore = useCallback(() => {
    const oldValue = getLocalOrDefaultValue(props);
    return oldValue === props.defaultValue
      ? setLocalOrDefaultValue(props.key, value)
      : setValue(oldValue);
  }, [props, value]);

  useWindowEvent('storage', handleStore);

  return [value, setValue];
};

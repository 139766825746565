//#region
import { useRef, useState } from 'react';
import { newUUID } from '../../Utilities/Core';
import { useSetState, useShallowEffect } from '@mantine/hooks';
import { Button, Container, Grid, Group, Paper, Title } from '@mantine/core';
import { useStateProvider } from '../../App/State';
import { useAsync } from '../../Hooks/useAsync';
import { useAxios } from '../../Hooks/useAxios';
//#endregion

// eslint-disable-next-line
export default (props: any) => {
  const Ref: any = useRef();
  const { state } = useStateProvider();
  const [isStopLoss, setStopLoss] = useState(false);
  const [trade, setTrade] = useSetState<ITrade>({});
  const [currencyRate, setCurrencyRate] = useState(0);
  const [statusText, setStatusText] = useState('00:00:00');
  const { axios } = useAxios();

  useShallowEffect(() => {
    if (props.config?.aTotal && props.config?.pTrade) {
      if (props.trades.length > 0) {
        const last = props.trades[props.trades.length - 1];
        trade.aTotal = trade.aInvest = last.aTotal;
        if (last.aTotal && last.aTrade) {
          trade.aTrade =
            trade.status === 'LOSS'
              ? Math.round((last.aTrade * props.config?.pIterate * 3) / 100)
              : Math.round((last.aTotal * props.config?.pTrade) / 100);
        }
      } else {
        trade.aTotal = trade.aInvest = props.config?.aTotal;
        trade.aTrade = Math.round(
          (props.config?.aTotal * props.config?.pTrade) / 100
        );
      }
      trade.id = newUUID();
      setTrade(trade);
      onStopLoss();
    }
  }, [props.trades, props.config]);

  useAsync(async () => {
    const { data } = await axios.request({
      method: 'GET',
      url: 'https://open.er-api.com/v6/latest/USD',
    });
    data && data.rates.INR && setCurrencyRate(data.rates.INR);
  });

  //#region
  const onResultTrade = (status: 'WIN' | 'LOSS') => {
    trade.status = status;
    if (props.config?.aTotal && props.config?.pReturn) {
      if (trade.aTotal && trade.aTrade) {
        const aTotal =
          status === 'LOSS'
            ? trade.aTotal - trade.aTrade
            : trade.aTotal + (trade.aTrade * props.config?.pReturn) / 100;
        trade.aTotal = Math.round(aTotal);
        props.setTrades.append(trade);
        setTrade(trade);
      }
    }
  };

  const onStopLoss = () => {
    setStopLoss(false);
    const WIN = props.trades.filter((t: any) => t.status === 'WIN').length;
    const LOSS = props.trades.filter((t: any) => t.status === 'LOSS').length;
    if (
      WIN === state?.maxWin ||
      LOSS === state?.maxLoss ||
      (trade?.aTrade && trade?.aTrade < 70) ||
      props.trades.length === state?.maxTrade ||
      (trade.aTotal && trade.aTotal < (props.config?.aTotal * 50) / 100)
    ) {
      setStopLoss(true);
      setStatusText('Stop Loss!!!');
    } else if (WIN > 0 || LOSS > 0) {
      setStopLoss(true);
      clearTimer(getDeadTime());
    }
  };

  const clearTimer = (e: any) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setStatusText(`00:${state?.maxWait}:00`);

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    state?.maxWait &&
      deadline.setMinutes(deadline.getMinutes() + parseInt(state?.maxWait));
    return deadline;
  };

  const getTimeRemaining = (e: any) => {
    const date = new Date();
    const total = Date.parse(e) - Date.parse(date.toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setStatusText(
        (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    } else {
      setStopLoss(false);
      clearInterval(Ref.current);
    }
  };

  //#endregion

  return (
    <Container size="xs" p={0}>
      <Paper>
        <Grid className="font-bold">
          <Grid.Col span={6}>
            <Group className="mt-1 px-4">
              <Title order={4} ml={-14} mt={-1} c="yellow">
                {trade?.aTrade} /{' '}
                {trade?.aTrade && currencyRate
                  ? (trade?.aTrade / currencyRate).toFixed(1)
                  : 0}
              </Title>
            </Group>
          </Grid.Col>
          <Grid.Col span={6} className="py-3">
            {isStopLoss ? (
              <Title className="float-end" order={2} c="red" mt={-8} mr={8}>
                {statusText}
              </Title>
            ) : (
              <Group grow className="mr-2 -mt-2">
                <Button color="green" onClick={() => onResultTrade('WIN')}>
                  WIN
                </Button>
                <Button color="red" onClick={() => onResultTrade('LOSS')}>
                  LOSS
                </Button>
              </Group>
            )}
          </Grid.Col>
        </Grid>
      </Paper>
    </Container>
  );
};
